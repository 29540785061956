import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "2020" }}>
    <SEO title="2020" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" style={{ width: `1400vw`, background:"#FDDD00" }}
    >
      {/* <div className="pages"> */}

      <PageItem  >
          <Img fluid={props.data.zwanzigzwanzig01.childImageSharp.fluid} />
        </PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig02.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig03.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig04.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig05.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig06.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig07.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig08.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig09.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig10.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig11.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig12.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig13.childImageSharp.fluid} /></PageItem>
      <PageItem  ><Img fluid={props.data.zwanzigzwanzig14.childImageSharp.fluid} /></PageItem>


      {/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










      {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    zwanzigzwanzig01: file(relativePath: { eq: "2020_1.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig02: file(relativePath: { eq: "2020_2.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig03: file(relativePath: { eq: "2020_3.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig04: file(relativePath: { eq: "2020_4.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig05: file(relativePath: { eq: "2020_5.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig06: file(relativePath: { eq: "2020_6.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig07: file(relativePath: { eq: "2020_7.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig08: file(relativePath: { eq: "2020_8.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig09: file(relativePath: { eq: "2020_9.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig10: file(relativePath: { eq: "2020_10.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig11: file(relativePath: { eq: "2020_11.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig12: file(relativePath: { eq: "2020_12.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig13: file(relativePath: { eq: "2020_13.jpg" }) {
      ...fluidImage
    }
    zwanzigzwanzig14: file(relativePath: { eq: "2020_14.jpg" }) {
      ...fluidImage
    }
  }
`